import React from 'react';
import PrioritizePage from './PrioritizePage.component';
import MetaInputNavigation from '../MetaInputNavigation.component';
import State from '../../../store/state';
import {onClickAbort} from '../../../../server/utils/summary.utils';
import {getTaxonomyLeavesMap} from '../../../../server/utils/taxonomy.utils';
import {PreviousPageButton, NextPageButton} from '../Pager.component';
import MetaSummaryContainer from '../summary/MetaSummaryContainer.component';
import {getTaxonomyWithCharacters} from '../../../utils/taxonomy.utils';

export default class PrioritizeContainer extends React.Component {
  constructor() {
    super();

    const {selectedElements, taxonomy, material, mainCharacters} = State.getState();
    const elementList = getTaxonomyLeavesMap(taxonomy);
    this.state = {
      selectedElements,
      taxonomy,
      material,
      elementList,
      mainCharacters
    };
  }

  componentDidMount() {
    State.subscribe(this.onGlobalStateChange);
  }

  componentWillUnmount() {
    State.unsubscribe(this.onGlobalStateChange);
  }

  onGlobalStateChange = ({selectedElements}) => {
    this.setState({selectedElements});
  };
  onToggleElement = (element) => {
    const selectedElements = this.state.selectedElements.map((e) => {
      if (e.id === element.id) {
        e.score = e.score !== 2 ? 2 : 1;
      }
      return e;
    });
    State.setState({selectedElements});
  };

  groupElementsByParent(elements) {
    const groups = {};
    elements
      .map((e) => {
        return {...this.state.elementList[e.id], ...e};
      })
      .forEach((element) => {
        let groupName;
        if (element.parents.includes('miljø')) {
          groupName = 'Miljø';
        } else if (element.parents.includes('handler om')) {
          groupName = 'Handler om';
        } else if (element.parents.includes('stemning')) {
          groupName = 'Stemning';
        } else {
          return;
        }
        if (!groups[groupName]) {
          groups[groupName] = {
            title: groupName,
            items: []
          };
        }
        groups[groupName].items.push(element);
      });

    // eslint-disable-next-line
    return Object.entries(groups).map(([key, value]) => value);
  }

  render() {
    const {taxonomy, selectedElements, mainCharacters} = this.state;
    const taxonomyLeavesMap = getTaxonomyLeavesMap(taxonomy);
    const parsedElements = selectedElements.map((element) => {
      element.parents = taxonomyLeavesMap[element.id].parents;
      return element;
    });
    const priorityGroups = this.groupElementsByParent(parsedElements);
    const taxonomyWithCharacters = getTaxonomyWithCharacters(taxonomy, mainCharacters);

    return (
      <div className="meta--wrapper mb6">
        <MetaInputNavigation
          currentPageSpecial={'Metakompas'}
          prevPageSpecial={'Rediger indtastning'}
          onNavigation={() => this.props.navigate('/meta')}
        />

        <div className="meta--container container">
          <div className="col-lg-3 col-md-3 col-sm-4" />
          <div className="col-lg-6 col-md-6 col-sm-4">
            <div>
              {priorityGroups.length ? (
                <PrioritizePage groups={priorityGroups} onToggleElement={this.onToggleElement} />
              ) : (
                ''
              )}
            </div>
            <div className="meta--subnav">
              <PreviousPageButton navigateTo="/meta" />
              <NextPageButton navigateTo="/sentences" data-cy="prioritize-next-button" />
            </div>
            <div>
              <button className="btn btn-link pl0" onClick={onClickAbort}>
                Annullér
              </button>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-4">
            <MetaSummaryContainer
              taxonomy={taxonomyWithCharacters}
              selectedElements={selectedElements}
              mainCharacters={mainCharacters}
              navigate={this.props.navigate}
              onAddSentencePage={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
